class NewsletterForm {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.form = el;
        this.button = this.form.querySelector('button[submit]');
        this.statusEl = this.form.querySelector('.form__output');

        return true;
    }

    init() {
        this.setSubmit();
    }

    setSubmit() {
        if (!this.form) return;
        this.form.addEventListener('submit', event => this.onSubmit(event));

        if (this.button)
            this.button.addEventListener('submit', event =>
                this.onSubmit(event)
            );
    }

    async onSubmit(event) {
        event.preventDefault();
        const formData = new FormData(this.form);

        const req = await fetch(`${home_url}/wp-json/newsletter/v1/save`, {
            body: formData,
            method: 'POST',
        });

        const res = await req.json();
        const resCode = req.status;
        const { status } = res;
        const { message, details } = status;

        this.statusEl.classList.remove(
            ...['has-errors', 'is-warning', 'is-success']
        );
        this.statusEl.innerHTML = '';
        this.statusEl.classList.add('d-none');

        if (![200, 202].includes(resCode)) {
            this.statusEl.classList.add('has-errors');
        } else {
            this.statusEl.classList.add('is-success');
        }

        this.statusEl.classList.remove('d-none');
        this.statusEl.innerHTML = `<span>${message}</span>`;
    }
}

const initNewsletter = () => {
    const newsletter = document.querySelectorAll('.form-newsletter');
    if (!newsletter) return;

    newsletter.forEach(item => new NewsletterForm(item));
};

initNewsletter();
